<template>
  <v-card>
    <v-card-title class="headline">Add gym</v-card-title>
    <v-divider class="mb-4" />

    <v-card-text>
      <v-text-field v-model="newGym.name" label="Name" hide-details @input="updateSlug" />
      <v-text-field v-model="newGym.slug" label="Slug" hide-details disabled readonly />
      <v-text-field v-model="newGym.name_short" label="Name short" hide-details />
      <v-text-field v-model="newGym.id_name" label="Id name (brand_boulder_city)" hide-details />
      <v-radio-group v-model="newGym.gym_type" hide-details>
        <v-radio label="Boulder gym" color="primary" :value="'boulder'" />
        <v-radio label="Climbing gym" color="primary" :value="'climbing'" />
      </v-radio-group>
      <v-checkbox v-model="newGym.has_routes" label="Has routes" hide-details color="primary" />
      <tl-country-select v-model="newGym.country" />
      <v-text-field type="number" v-model="newGym.latitude" label="Latitude" hide-details />
      <v-text-field type="number" v-model="newGym.longitude" label="Longitude" hide-details />
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">Cancel</v-btn>
      <v-btn text color="primary" :loading="saving" @click="add">Add</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import errorService from '@/services/error-service'
import tlCountrySelect from '@/components/profile/tl-country-select'
import Gym from '@/models/Gym'

export default {
  components: {
    tlCountrySelect,
  },
  data: () => ({
    saving: false,
    newGym: {
      auto_grade_stable_votes: 5,
    },
  }),
  methods: {
    ...mapActions('dialog', ['close']),
    updateSlug() {
      this.newGym.slug = this.newGym.name.replace(/\W+/g, '-').toLowerCase()
    },
    add() {
      this.saving = true
      Gym.$apiCreate(this.newGym)
        .then(() => {
          this.$store.dispatch('toast/success', `Gym added`)
          this.close()
        })
        .catch(errorService.toast)
        .finally(() => (this.saving = false))
    },
  },
}
</script>
